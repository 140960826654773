<template>
  <div v-if="!blok.isModalBtn">
    <NuxtLink
      v-if="blok.link.cached_url"
      :id="'btn-' + (blok.id != '' ? blok.id : blok._uid)"
      v-editable="blok"
      :to="
        blok.link.linktype === 'url'
          ? blok.link.cached_url
          : '/' + blok.link.cached_url
      "
      :aria-label="blok.aria_label != '' ? blok.aria_label : undefined"
      :class="[
        'cta cta--' + blok.style,
        blok.app_icons && blok.style != 'tertiary' && 'cta--app-icons',
        blok.preload ? blok.preload : '',
      ]"
      :rel="
        blok.link.cached_url.includes('shiftkey')
          ? ''
          : blok.link.target === '_blank'
          ? 'nofollow noopener'
          : undefined
      "
      :target="blok.link.target === '_blank' ? blok.link.target : ''"
    >
      {{ blok.display_name }}
    </NuxtLink>
  </div>
  <div v-else class="hubspot-modal">
    <div
      v-editable="blok"
      :class="showThankYou ? 'hubspot-modal__form-submitted' : ''"
    >
      <div class="hubspot-modal__success">
        <h4>Thank you!</h4>
        <p>The form was successfully submitted.</p>
      </div>
      <button
        class="modal-btn"
        :class="['cta cta--' + blok.style]"
        @click="showModal()"
      >
        {{ blok.display_name }}
      </button>
    </div>
    <transition name="modal-fade">
      <div class="experiment-select">
        <div
          class="hubspot-modal__modal-backdrop"
          :class="hideModal ? 'hide-modal' : ''"
        >
          <div
            class="modal p-4 md:p-9 md:w-[524px] max-w-[524px]"
            role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <section id="modalDescription" class="modal-body">
              <button class="btn-close" @click="closeModal">
                <span class="material-symbols-outlined modal-icon">
                  close
                </span>
              </button>
              <header id="modalTitle" class="modal-header">
                <CustomRTE
                  v-if="blok.modal_heading && blok.modal_heading.length > 0"
                  class="modal-title"
                  :blok="blok.modal_heading[0]"
                />
              </header>
              <div
                :id="formID"
                class="hubspot-form mr-auto max-w-[844px]"
              ></div>
            </section>
            <footer class="modal-footer pt-4"></footer>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { CTABlokProps } from '@/types';

// Props
const props = defineProps<{
  blok: CTABlokProps;
}>();

const hideModal = ref(true);
const formID = ref(
  props.blok.custom_id
    ? props.blok.custom_id
    : 'H-' + (props.blok.form_id ?? '').replace(/-/g, '')
);

const showModal = () => {
  hideModal.value = false;
};

const closeModal = () => {
  hideModal.value = true;
};

onMounted(() => {
  if (props.blok.form_id) {
    window.hbspt.forms.create({
      formId: props.blok.form_id,
      formInstanceId: formID.value,
      portalId: '20991303',
      region: 'na1',
      target: '#' + formID.value,
    });
  }
});
</script>

<style lang="scss">
.cta {
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  justify-content: center;
  outline-offset: 4px;
  position: relative;
  text-align: center;
  white-space: nowrap;

  &--primary,
  &--secondary {
    border-radius: 2px;
    padding-block: 10px;
    padding-inline: 16px;
    text-decoration: none;

    @media all and #{$mq-m-min} {
      padding-block: 12px;
      padding-inline: 24px;
    }
  }

  &--primary {
    background-color: var(--lightest);
    border: 1px solid var(--lightest);
    color: var(--darkest);

    /*@media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--bright-green);
        border: 1px solid var(--bright-green);
      }
    }*/

    &:focus-visible {
      background-color: var(--bright-green);
      border: 1px solid var(--bright-green);
      outline: 1px solid var(--bright-green);
    }
  }

  &--secondary {
    border: 1px solid var(--lightest);
    color: var(--lightest);

    /*@media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: var(--lightest);
        color: var(--darkest);
      }
    }*/

    &:focus-visible {
      background-color: var(--lightest);
      color: var(--darkest);
      outline: 1px solid var(--lightest);
    }
  }

  &--tertiary {
    color: var(--lightest);
    padding-block-end: 4px;
    text-underline-offset: 6px;

    &:focus-visible {
      outline: 1px solid var(--lightest);
      outline-offset: 4px;
    }
  }

  &--app-icons {
    margin-inline-end: var(--offset);

    @media all and #{$mq-s-max} {
      --offset: 72px;
    }
    @media all and #{$mq-m-min} {
      --offset: 100px;
    }

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='29' fill='%238E9193'%3E%3Cpath d='M19.561 15.418c-.03-3.56 2.919-5.295 3.056-5.372-1.672-2.433-4.262-2.767-5.175-2.79-2.179-.227-4.286 1.3-5.396 1.3-1.128 0-2.836-1.276-4.68-1.24-2.37.036-4.585 1.407-5.796 3.53C-.938 15.18.93 21.548 3.336 25.054c1.2 1.717 2.603 3.631 4.441 3.566 1.797-.072 2.471-1.145 4.638-1.145 2.149 0 2.782 1.145 4.656 1.103 1.928-.03 3.146-1.723 4.304-3.452 1.391-1.968 1.946-3.905 1.97-4.001-.048-.03-3.749-1.443-3.785-5.706zm-3.54-10.47c.967-1.21 1.63-2.85 1.445-4.52-1.397.06-3.152.966-4.155 2.146-.889 1.043-1.689 2.749-1.48 4.353 1.564.113 3.182-.793 4.191-1.98z'/%3E%3C/svg%3E"),
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='26' fill='%238E9193' %3E%3Cpath d='M.877 24.926c-.375-.187-.563-.656-.563-1.125v-.375V2.705c0-.375.094-.656.188-1.031.094-.188.282-.375.469-.563l11.921 12.095c-4.036 3.75-7.979 7.688-12.015 11.72zm16.88-16.769l-3.473 3.469L2.926.094S2.926 0 3.02 0l14.738 8.157zM2.926 25.848l11.358-11.251 3.379 3.188L3.02 25.848h-.094zm16.319-9.361l-3.567-3.375 3.661-3.657c.375.187.751.375 1.127.656l2.91 1.594c.282.094.469.281.657.563.375.469.375.938 0 1.407-.188.188-.375.375-.657.469-1.314.844-2.722 1.594-4.13 2.344z'/%3E%3C/svg%3E");
      background-repeat: no-repeat, no-repeat;
      content: '';
      display: inline-flex;
      height: 100%;
      inset-block-start: 50%;
      pointer-events: none;
      position: absolute;
      transform: translateY(-50%);

      @media all and #{$mq-s-max} {
        background-position: 0 calc(50% - 2px), 34px center;
        background-size: 18px auto, 18px auto;
        inset-inline-end: calc(var(--offset) * -1);
        width: 52px;
      }

      @media all and #{$mq-m-min} {
        background-position: 0 calc(50% - 2px), 44px center;
        inset-inline-end: calc(var(--offset) * -1);
        width: 70px;
      }
    }
  }
}
.hubspot-modal {
  .modal-btn {
    text-decoration: underline;
  }
  .cta--primary,
  .cta--secondary {
    text-decoration: none;
  }
  &__show-modal-btn {
    @include primary-button;
    margin-block-start: var(--spacing-s);
  }
  &__success {
    display: none;
    margin-block-start: var(--spacing-xl);
    h4 {
      margin-block-end: var(--spacing-xs);
    }
  }
  &__form-submitted {
    form {
      display: none;
    }
    .hubspot-modal__success {
      display: block;
    }
  }

  &__modal-backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal {
      background: var(--chalk);
      max-height: 90%;
      overflow-y: scroll;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      border-radius: 2px var(--section-border-radius) 2px 2px;
      background-image: url('/img/textures/texture-3.png');
      background-position: top center;
      background-repeat: repeat;
      margin-inline: 4px;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .modal::-webkit-scrollbar {
      display: none;
    }
    .modal-header,
    .modal-footer {
      display: flex;
    }
    .modal-header {
      position: relative;
      justify-content: space-between;
    }
    .modal-title {
      color: var(--dark-gray);
    }
    .modal-footer {
      flex-direction: column;
    }
    .modal-body {
      position: relative;
      padding: 20px 10px;
    }

    .btn-close {
      cursor: pointer;
      margin-left: 95%;
      font-weight: 900;
      color: var(--dark-gray);

      .modal-icon {
        font-variation-settings: 'FILL' 0, 'wght' 500, 'GRAD' 200;
      }
    }
    .btn-close:disabled,
    button[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .btn-green {
      color: white;
      background: #4aae9b;
      border: 1px solid #4aae9b;
      border-radius: 2px;
    }
    .modal-fade-enter,
    .modal-fade-leave-to {
      opacity: 0;
    }
    .modal-fade-enter-active,
    .modal-fade-leave-active {
      transition: opacity 0.5s ease;
    }

    .lead-select {
      width: 100%;
      height: 51px;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding-left: 5px;
      padding-right: 5px;
      margin-block-start: 0.875rem !important;
    }
    .lead-select:focus {
      outline: none !important;
      &:focus-visible {
        outline: 1px solid var(--lightest);
        outline-offset: 2px;
      }
    }
    .hide-experiment {
      display: none;
    }

    .hubspot-form input,
    .hubspot-form select {
      width: 100%;
      height: 36px;
      overflow: hidden;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding-left: 5px;
      padding-right: 5px;
      margin-block-start: 0.875rem !important;
      color: var(--neutral);
    }

    .field {
      margin-bottom: 1.25rem;
    }

    .hubspot-form input:focus,
    .hubspot-form select:focus {
      outline: none !important;

      &:focus-visible {
        outline: 1px solid var(--lightest);
        outline-offset: 2px;
      }
    }

    .hubspot-form label {
      color: var(--neutral);
      font-size: 0.875rem;
    }

    form {
      margin-block-start: 40px;
      width: 100% !important;
      max-width: 844px !important;
      margin-inline-start: auto !important;
      margin-inline-end: auto !important;
    }

    [class^='form-columns-'] {
      width: 100% !important;
      max-width: 844px !important;
    }
    input,
    select {
      outline: none;

      &:focus-visible {
        outline: 1px solid var(--lightest);
        outline-offset: 2px;
      }
    }
    input,
    select {
      background-color: transparent;
      border: 1px solid var(--neutral);
      border-radius: 2px;
      min-height: 51px;
      padding-block: 12px;
      padding-inline: 24px;

      &:focus {
        outline: none;
      }

      &:focus-visible {
        border-width: 2px;
      }
    }

    input,
    select {
      background-color: transparent;
      /* stylelint-disable-next-line value-keyword-case */
      border: 1px solid var(--neutral);
      border-radius: 2px;
      /* stylelint-disable-next-line value-keyword-case */
      color: currentColor;
      cursor: pointer;
      display: grid;
      font: inherit;
      height: 24px;
      margin: 3px 0 0;
      place-content: center;
      transform: translateY(-0.075em);
      width: 24px;

      &::before {
        /* stylelint-disable-next-line value-keyword-case */
        background-color: var(--lightest);
        box-shadow: inset 16px 16px var(--darkest);
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        content: '';
        height: 10px;
        transform: scale(0);
        transform-origin: bottom left;
        width: 10px;
      }

      &:focus-visible {
        /* stylelint-disable-next-line value-keyword-case */
        outline: max(2px, 0.15em) solid currentColor;
        outline-offset: max(2px, 0.15em);
      }

      &:disabled {
        cursor: not-allowed;
      }

      &:checked {
        /* stylelint-disable-next-line value-keyword-case */
        background-color: currentColor;
        /* stylelint-disable-next-line value-keyword-case */
        border-color: currentColor;

        &::before {
          transform: scale(1);
        }
      }
    }

    select {
      padding-right: 16px !important;
    }

    input.error,
    select.error {
      border-color: var(--red) !important;
    }
    .hs-error-msgs {
      margin-block-end: 0;
    }
    .hs-error-msg {
      color: var(--red) !important;
      font-size: 0.75rem !important;
      inset-block-start: 100% !important;
      inset-inline-start: 0 !important;
      margin-block: 0 !important;
      margin-block-start: 4px !important;
    }

    .hs-form-required {
      color: green !important;
      font-weight: 900;
    }
    .hs-submit .actions {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media all and #{$mq-xs-max} {
        align-items: flex-start;
      }

      @media all and #{$mq-xs-min} {
        flex-direction: row;
      }
    }

    input[type='submit'] {
      width: auto !important;
      align-items: center;
      display: flex;
      font-size: 0.875rem;
      justify-content: center;
      outline-offset: 4px;
      position: relative;
      white-space: nowrap;
      background: var(--dark-gray);
      color: #fff !important;
      text-align: center !important;
      cursor: pointer;
      border-radius: 2px !important;
      padding-block: 10px !important;
      padding-inline: 16px !important;
      text-decoration: none;

      @media all and #{$mq-m-min} {
        padding-block: 12px !important;
        padding-inline: 24px !important;
      }
    }
  }
}

.hide-modal {
  display: none;
}
</style>
